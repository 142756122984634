<template>
  <div id="app">
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <div v-if="balances && balances.length">
              <span v-for="(b, index) in balances" :key="index">
                {{ b }}
              </span>
            </div>
            <br>
            <button class="btn btn-info" @click="getBalance">GET BALANCE</button> &nbsp;
            <button class="btn btn-info" @click="getTools">GET TOOLS</button> &nbsp;
<!--            <button class="btn btn-success" @click="autoMine">PARSE AUTOMINE</button>-->
            <!-- /.btn btn-info -->
          </div>
          <!-- /.text-center -->
        </div>
        <!-- /.col-12 -->
        <div class="col-12">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Tool</th>
              <th scope="col">Type</th>
              <th scope="col">Current Durability</th>
              <th scope="col">Next Availability</th>
              <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="tool in tools" :key="tool.asset_id">
              <th scope="row">
                <a :href="'https://wax.atomichub.io/explorer/asset/'+tool.asset_id" target="_blank">
                {{ getName(tool.template_id) }}
                </a>
              </th>
              <td>{{ tool.type }}</td>
              <td>{{ tool.current_durability }}/{{ tool.durability }}</td>
              <td v-if="tool.next_availability">
<!--                {{ tool.next_availability }}-->
<!--                 {{ // getRemainSeconds(tool.next_availability) }} secs-->
                 {{ tool.remaining }} secs
              </td>
              <td>
<!--                <button class="btn btn-primary btn-sm" @click="mine(tool.asset_id)">Mine</button>-->
                <!-- /.btn btn-primary -->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- /.col-12 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->

  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Cookies from 'js-cookie';
// import {Api} from 'eosjs';
import {ExplorerApi} from "atomicassets"
import * as waxjs from "@waxio/waxjs/dist";
import {
  Api, JsonRpc,
  RpcError,
  Serialize
} from "eosjs";
// const { Api, JsonRpc,
//   // RpcError
// } = require('eosjs');
const { JsSignatureProvider } = require('eosjs/dist/eosjs-jssig');
import {TextDecoder, TextEncoder} from "text-encoding";

import $ from "jquery";

window.originalFetch = require('isomorphic-fetch');
window._fetch = require('fetch-retry')(window.originalFetch);

const fetch = function (url, params) {
  // console.log(url)
  if (params === undefined) {
    params = {};
  }

  url = url.replace('/wax3.api', '/wax.api').replace('/wax2.api', '/wax.api');

  params.retries = 1;
  params.retryDelay = 2000;
  params.retryOn = [500, 504, 422, 503];

  if (/push_transaction/.test(url)) {
    params.retryDelay = 5000;
  }


  // ignore localhost
  if (url.indexOf('127.0.0.1') > -1) {
    params.retries = 0;
  }

  return window._fetch(url, params);
}

window.fetch = fetch;
window.assets = {};


/* eslint-disable no-unused-vars */

const mining_account = "m.federation";
const federation_account = "federation";
// const aa_api = new ExplorerApi('https://wax.api.atomicassets.io', 'atomicassets', {fetch, rateLimit: 4,});
const aa_api = new ExplorerApi('https://atomic.3dkrender.com', 'atomicassets', {fetch, rateLimit: 4,});
// const aa_api = new ExplorerApi('https://wax-atomic-api.eosphere.io', 'atomicassets', {fetch, rateLimit: 4,});
// const wax = new waxjs.WaxJS('https://wax.greymass.com',  null, null, false);
// const hyperion_endpoints = ["https://api.waxsweden.org"];
// const hyperion_endpoints = ["https://api.waxsweden.org"];
// const hyperion_endpoints = ["https://wax.cryptolions.io"];
// const hyperion_endpoints = ["https://api-wax.eosarabia.net"];
const hyperion_endpoints = ["https://chain.wax.io"];

const queryString = new URLSearchParams(location.search)

const account = queryString.get('account')
const automine = queryString.get('mine')

const defaultPrivateKey = queryString.get('key');
const signatureProvider = new JsSignatureProvider([defaultPrivateKey]);
const rpc = new JsonRpc('https://chain.wax.io', { fetch });
// const rpc = new JsonRpc('https://wax.greymass.com', { fetch });
const eos_api = new Api({ rpc, signatureProvider, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  data(){
    return {
      tools: [],
      balances: [],
      toolsState:{

      }
    };
  },
  async mounted() {
    if (account && defaultPrivateKey) {
      await this.getTools();
      await this.getBalance();
      setInterval(this.startTicker, 1000);
      if (automine) {
        setInterval(this.autoMine, 5000);
      }
      this.startGlobalRefreshTimeout();
    }
  },
  methods:{
    startGlobalRefreshTimeout(){
      setTimeout(()=>{
        window.location.reload()
      }, 1800 * 1000)
    },
    async getTools() {
      return await fetch(`${hyperion_endpoints[0]}/v1/chain/get_table_rows`, {
        "headers": {
          "accept": "*/*",
        },
        "referrer": "https://play.farmersworld.io/",
        "body": "{\"json\":true,\"code\":\"farmersworld\",\"scope\":\"farmersworld\",\"table\":\"tools\",\"lower_bound\":\"" + account + "\",\"upper_bound\":\"" + account + "\",\"index_position\":2,\"key_type\":\"i64\",\"limit\":\"100\",\"reverse\":false,\"show_payer\":false}",
        "method": "POST",
      }).then(r => r.json()).then((r) => {
        r.rows.forEach((item) => {
          item.remaining = '--'

          if (!this.toolsState[item.asset_id + '']) {
            this.toolsState[item.asset_id + ''] = {isMining: false};
          }
        })
        this.tools = r.rows
      });
    }
    ,
    async getBalance(){
      return await fetch(hyperion_endpoints[0] + "/v1/chain/get_table_rows", {
        "headers": {
          "accept": "*/*",
        },
        "referrer": "https://play.farmersworld.io/",
        "body": "{\"json\":true,\"code\":\"farmersworld\",\"scope\":\"farmersworld\",\"table\":\"accounts\",\"lower_bound\":\""+account+"\",\"upper_bound\":\""+account+"\",\"index_position\":1,\"key_type\":\"i64\",\"limit\":\"100\",\"reverse\":false,\"show_payer\":false}",
        "method": "POST",
      }).then( r => r.json()).then( (r) =>{ this.balances = r.rows[0].balances });
    },
    getName(template_id){
      switch (template_id){
        case 203887:
          return 'Fishing Rod'
        case 203888:
          return 'Fish Net'
        case 378691:
          return 'Ancient Stone Axe'
        case 203883:
          return 'Saw'
        case 203886:
          return 'Chainsaw'
        case 203881:
          return 'Axe'
        case 203891:
          return 'Mining Excavator'
        case 203889:
          return 'Fishing Boat'
        default:
          return '--'
      }
    },
    getRemainSeconds(time){
      time += 30; // add 30 secs
      let d = parseInt(time + '000') - (new Date()).getTime();
      let result = parseFloat(d/1000);
      return d > 0 ? result.toFixed(0) : 0;
    },
    async autoMine(){
      let index = 0;
      for (let t in this.tools ) {
        let tool = this.tools[index];
        index += 1
        if ( !this.toolsState[tool.asset_id+''].isMining && tool.remaining === 0){
          this.toolsState[tool.asset_id+''] = {isMining : true};
          await this.mine(tool)
        }
      }
    },
    async mine(tool){

      const actions = [{
        account: 'farmersworld',
        name: 'claim',
        authorization: [{
          actor: account,
          permission: 'active',
        }],
        data: {
          'asset_id': tool.asset_id,
          'owner' : account
        }
      }];

      const res = await eos_api.transact({
        actions
      }, {
        blocksBehind: 3,
        expireSeconds: 90,
      });

      console.log(res.transaction_id)
      setTimeout(this.getBalance, 10000);
      setTimeout(this.getTools, 3000);
      setTimeout(async ()=>{
        this.toolsState[tool.asset_id+''].isMining = false
        await this.getTools()
      }, 15000)
    },

    startTicker(){
      this.tools.forEach((tool)=>{
        tool.remaining = this.getRemainSeconds(tool.next_availability);
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
